import classNames from 'classnames';
import NextImage, { StaticImageData } from 'next/image';

import { TExtendedVerticalGroups } from '../../content/policy-links/products';
import styles from './InsuranceCard.module.scss';

export interface InsuranceCardProps {
  img: {
    src: StaticImageData;
    alt: string;
  };
  title: string;
  group: TExtendedVerticalGroups;
  excerpt: string;
}

const InsuranceCard = (props: InsuranceCardProps) => {
  const { img, title, excerpt, group } = props;

  return (
    <div className={classNames('d-flex ai-center', styles.cardWrapper)}>
      <div
        className={classNames(
          'br8 d-flex ai-center jc-center mr16',
          styles.imgWrapper
        )}
      >
        <NextImage
          src={img.src}
          alt={img.alt}
          className={classNames(styles.img)}
        />
      </div>
      <div className={styles.content}>
        <h3 className="p-h4">
          {title}{' '}
          {group === 'company' && (
            <span className={classNames('tc-grey-500', styles.companyTag)}>
              Company
            </span>
          )}
        </h3>
        <p className="p-p--small tc-grey-600">{excerpt}</p>
      </div>
    </div>
  );
};

export default InsuranceCard;
